import * as React from 'react';
import Box from '@mui/material/Box';

const HEIGHT = 60;
const WIDTH = 60;

export function Logo({ height = HEIGHT, width = WIDTH, src = '' }) {
  let url;

  if (src) {
    url = src;
  }

  return <Box alt="logo" component="img" height={height} src={url} width={width} />;
}
