import React, { useState } from 'react';
import { Box, Typography, Button, Card, CardContent, IconButton, Divider, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const styles = {
  card: {
    borderRadius: '12px',
    boxShadow: 'none !important',
    border: '1px solid #E0E0E0',
    padding: '8px',
    mb: '16px',
    width: '100%',
  },
  cardContent: {
    p: '16px !important',
  },
  dividerHorizontal: {
    my: 2,
    ml: '-25px',
    mr: '-25px',
  },
  dividerVertical: {
    mb: '-25px',
    mt: '-16px',
  },
  dividerVerticalWithMargin: {
    mt: '-16px',
    mb: '-25px',
    mr: '10px',
  },
  button: {
    height: '40px',
    backgroundColor: '#7635FF',
    textTransform: 'none',
    borderRadius: '8px',
    fontWeight: 'bold',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#5C27D9',
    },
  },
};

const ClientCard = ({ logo, name, description, stats, buttonLabel, onButtonClick, onGoToStaging, onOpenAdmin }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = async () => {
    setIsLoading(true);
    try {
      await onButtonClick();
    } catch (error) {
      console.error(`Error navigating to ${name} dashboard:`, error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card sx={styles.card}>
      <CardContent sx={styles.cardContent}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" gap={2}>
            <img src={logo} alt={`${name} logo`} />
          </Box>
          <IconButton onClick={handleMenuClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            sx={{ borderRadius: '8px' }}
            elevation={2}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem
              onClick={() => {
                handleMenuClose();
                onGoToStaging();
              }}
            >
              Go to Staging Dashboard
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleMenuClose();
                onOpenAdmin();
              }}
            >
              Open Admin Panel
            </MenuItem>
          </Menu>
        </Box>
        <Divider sx={styles.dividerHorizontal} />
        <Box display="flex" sx={{ height: '50px' }}>
          <Box flex={1} pr={2} pt={2}>
            <Typography variant="body1" fontWeight="bold">
              {description}
            </Typography>
          </Box>
          <Divider orientation="vertical" flexItem sx={styles.dividerVertical} />
          <Box flex={2} display="flex">
            {stats.map((stat, index) => (
              <React.Fragment key={index}>
                <Box sx={{ textAlign: 'center', flex: 1 }}>
                  <Typography variant="caption" color="textSecondary">
                    {stat.label}
                  </Typography>
                  <Typography variant="h6" fontWeight="bold">
                    {stat.value}
                  </Typography>
                </Box>
                {index < stats.length - 1 && <Divider orientation="vertical" flexItem sx={styles.dividerVertical} />}
              </React.Fragment>
            ))}
            <Divider orientation="vertical" flexItem sx={styles.dividerVerticalWithMargin} />
            <Box sx={{ display: 'flex', textAlign: 'center', flex: 1.5, pt: 1 }}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleClick}
                disabled={isLoading}
                sx={styles.button}
              >
                {isLoading ? 'Loading...' : buttonLabel}
              </Button>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ClientCard;
