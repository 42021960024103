import React, { createContext, useState, useContext } from 'react';

const ClientContext = createContext();

export const ClientProvider = ({ children }) => {
  const [selectedClient, setSelectedClient] = useState('Tidal');

  return <ClientContext.Provider value={{ selectedClient, setSelectedClient }}>{children}</ClientContext.Provider>;
};

export const useClient = () => useContext(ClientContext);
