import { Box, Typography, Button } from '@mui/material';
import { useState, useEffect } from 'react';
import { useClient } from '../ClientContext';

const styles = {
  container: {
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    mb: '32px',
    p: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  subtitleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },
  title: {
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '24px',
    mb: '8px',
  },
  subtitle: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    color: '#344054',
  },
  lastEdited: {
    fontSize: '12px',
    fontStyle: 'italic',
    lineHeight: '20px',
    color: '#98A2B3',
    textAlign: 'right',
  },
  buttonOn: {
    backgroundColor: '#772EDD',
    color: '#FFFFFF',
    textTransform: 'none',
    fontWeight: 'bold',
    boxShadow: 'none',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: '#5C21BF',
    },
  },
  buttonOff: {
    color: '#475467',
    backgroundColor: 'transparent',
    border: '1px solid #475467',
    textTransform: 'none',
    fontWeight: 'bold',
    boxShadow: 'none',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: '#F3EAFF',
    },
  },
};

const MaintenanceModeCard = () => {
  const [maintenanceMode, setMaintenanceMode] = useState(null);
  const [lastChanged, setLastChanged] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { selectedClient } = useClient();

  useEffect(() => {
    const fetchMaintenanceStatus = async () => {
      try {
        const token = process.env.REACT_APP_SECRET;
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/admin/maintenance-mode?client=${encodeURIComponent(selectedClient)}`,
          {
            method: 'GET',
            credentials: 'include',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          },
        );

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setMaintenanceMode(data.data.isEnabled);
        setLastChanged(data.data.updatedAt ? new Date(data.data.updatedAt) : null);
      } catch (error) {
        console.error('Error:', error);
        setError(error.message);
      }
    };

    if (selectedClient === 'Tidal' || selectedClient === 'SoundExchange') {
      fetchMaintenanceStatus();
    }
  }, [selectedClient]);

  const handleButtonClick = () => {
    const newMode = !maintenanceMode;
    setLoading(true);
    setError(null);

    const token = process.env.REACT_APP_SECRET;

    fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/maintenance-mode`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        isEnabled: newMode,
        client: selectedClient,
      }),
    })
      .then((response) => {
        setLoading(false);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(() => {
        setMaintenanceMode(newMode);
      })
      .catch((error) => {
        console.error('Error:', error);
        setError(error.message);
      });
  };

  const formattedLastChanged = lastChanged ? lastChanged.toLocaleString() : 'N/A';

  return (
    <Box sx={styles.container}>
      <Box sx={styles.leftContainer}>
        <Typography sx={styles.title}>Maintenance</Typography>
        <Box sx={styles.subtitleContainer}>
          <Typography sx={styles.subtitle}>
            Maintenance mode is currently {maintenanceMode === null ? 'loading...' : maintenanceMode ? 'ON' : 'OFF'}
          </Typography>
          {selectedClient && <Typography sx={styles.subtitle}>Client: {selectedClient}</Typography>}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
        {maintenanceMode !== null && (
          <Typography sx={styles.lastEdited}>Last changed at {formattedLastChanged}</Typography>
        )}
        {error && (
          <Typography color="error" variant="body2">
            Error: {error}
          </Typography>
        )}
        <Button
          variant={maintenanceMode ? 'outlined' : 'contained'}
          sx={maintenanceMode ? styles.buttonOff : styles.buttonOn}
          onClick={handleButtonClick}
          disabled={loading || maintenanceMode === null || selectedClient === 'All Dashboards'}
        >
          {loading ? 'Processing...' : maintenanceMode ? 'Turn OFF Maintenance Mode' : 'Turn ON Maintenance Mode'}
        </Button>
      </Box>
    </Box>
  );
};

export default MaintenanceModeCard;
