import { Box, Typography, Divider, Button, Modal, CircularProgress } from '@mui/material';
import { useClient } from '../ClientContext';
import { useEffect, useState } from 'react';
import axios from 'axios';

const styles = {
  container: {
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    mb: '32px',
    p: '32px',
  },
  title: {
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '24px',
    mb: '24px',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #E0E0E0',
    borderRadius: '8px',
    padding: '24px',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
  },
  button: {
    backgroundColor: '#772EDD',
    textTransform: 'none',
    fontWeight: 'bold',
    boxShadow: 'none',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: '#5C21BF',
    },
  },
  divider: {
    my: '-25px',
  },
  closeButton: {
    marginTop: '16px',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: '#5C21BF',
    },
  },
};

const CacheCard = () => {
  const client = useClient();
  const [isDisabled, setIsDisabled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    setIsDisabled(client.selectedClient === 'SoundExchange' || client.selectedClient === 'All Dashboards');
  }, [client]);

  const buildCache = async () => {
    const cacheUrl = `${process.env.REACT_APP_BACKEND_URL}/cache/build-cache?userType=${client.selectedClient}`;

    try {
      setIsModalOpen(true);
      setLoading(true);
      setMessage('Starting the cache build process. Please do not close this popup.');

      const response = await axios.get(cacheUrl, {
        headers: { Authorization: `Bearer ${process.env.REACT_APP_SECRET}` },
      });

      if (response.status === 200) {
        setMessage('Cache building completed successfully.');
        setTimeout(() => setIsModalOpen(false), 3000); // Close modal after 3 seconds
      }
    } catch (error) {
      setLoading(false);
      setMessage(`Failed to start cache build process: ${error.message}`);
    }
  };

  const buildDailyCache = async () => {
    const dailyCacheUrl = `${process.env.REACT_APP_BACKEND_URL}/cache/build-daily-cache?userType=${client.selectedClient}`;

    try {
      setIsModalOpen(true);
      setLoading(true);
      setMessage('Starting the daily cache build process. Please do not close this popup.');

      const response = await axios.get(dailyCacheUrl, {
        headers: { Authorization: `Bearer ${process.env.REACT_APP_SECRET}` },
      });

      if (response.status === 200) {
        setMessage('Daily cache building completed successfully.');
        setTimeout(() => setIsModalOpen(false), 3000); // Close modal after 3 seconds
      }
    } catch (error) {
      setLoading(false);
      setMessage(`Failed to start daily cache build process: ${error.message}`);
    }
  };

  const flushCache = async () => {
    const flushCacheUrl = `${process.env.REACT_APP_BACKEND_URL}/cache/flush-cache?userType=${client.selectedClient}`;

    try {
      setIsModalOpen(true);
      setLoading(true);
      setMessage('Starting the cache flush process. Please do not close this popup.');

      const response = await axios.get(flushCacheUrl, {
        headers: { Authorization: `Bearer ${process.env.REACT_APP_SECRET}` },
      });

      if (response.status === 200) {
        setMessage('Cache flushing completed successfully.');
        setTimeout(() => setIsModalOpen(false), 3000); // Close modal after 3 seconds
      }
    } catch (error) {
      setLoading(false);
      setMessage(`Failed to flush cache: ${error.message}`);
    }
  };

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.title}>Cache</Typography>
      <Box sx={styles.buttonContainer}>
        <Box sx={styles.section}>
          <Button variant="contained" sx={styles.button} disabled={isDisabled} onClick={buildCache}>
            Build Cache
          </Button>
        </Box>
        <Divider orientation="vertical" sx={styles.divider} flexItem />
        <Box sx={styles.section}>
          <Button variant="contained" sx={styles.button} disabled={isDisabled} onClick={buildDailyCache}>
            Build Daily Cache
          </Button>
        </Box>
        <Divider orientation="vertical" sx={styles.divider} flexItem />
        <Box sx={styles.section}>
          <Button variant="contained" sx={styles.button} disabled={isDisabled} onClick={flushCache}>
            Flush Cache
          </Button>
        </Box>
      </Box>

      <Modal open={isModalOpen}>
        <Box sx={{ bgcolor: 'white', p: 4, mx: 'auto', my: '20%', maxWidth: 300, textAlign: 'center' }}>
          {loading ? <CircularProgress /> : null}
          <Typography sx={{ mt: 2 }}>{message}</Typography>
          {!loading && (
            <Button sx={styles.closeButton} variant="outlined" onClick={() => setIsModalOpen(false)}>
              Close
            </Button>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default CacheCard;
