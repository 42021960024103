import React from 'react';
import { Box, Avatar } from '@mui/material';
import { Logo } from './Logo';
import SideNav from './SideNav';

const styles = {
  container: {
    display: 'flex',
    minHeight: '100vh',
    backgroundColor: '#f5f5f5',
  },
  mainContent: {
    flexGrow: 1,
    width: '100vh',
  },
  header: {
    height: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    p: 3,
    backgroundColor: '#fff',
    border: '1px solid #e0e0e0',
  },
  avatar: {
    width: 45,
    height: 45,
  },
};

const Layout = ({ children, profilePic }) => {
  return (
    <Box sx={styles.container}>
      <SideNav />
      <Box sx={styles.mainContent}>
        <Box sx={styles.header}>
          <Logo src={'/assets/beatdapp-logo.png'} height={27} width={150} />
          <Avatar sx={styles.avatar} src={profilePic} />
        </Box>
        <Box>{children}</Box>
      </Box>
    </Box>
  );
};

export default Layout;
