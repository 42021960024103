import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Admin from './pages/Admin';
import UserManagement from './pages/UserManagement';
import CacheAndMaintenance from './pages/CacheAndMaintenance';
import ProtectedRoute from './components/ProtectedRoute'; // Ensure the correct path
import { AccessDenied } from './components/AccessDenied';

function App() {
  const { isLoading, isAuthenticated, loginWithRedirect, user } = useAuth0();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  if (!isAuthenticated) {
    return <div></div>;
  }

  return (
    <div>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute requiredRole="Admin">
                <Admin user={user} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin"
            element={
              <ProtectedRoute requiredRole="Admin">
                <Admin user={user} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/user-management"
            element={
              <ProtectedRoute requiredRole="Admin">
                <UserManagement user={user} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/cache-maintenance"
            element={
              <ProtectedRoute requiredRole="Admin">
                <CacheAndMaintenance user={user} />
              </ProtectedRoute>
            }
          />
          <Route path="/access-denied" element={<AccessDenied />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
