import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const roleRedirectMap = {
  Admin: process.env.REACT_APP_SSO_CLIENT_URL + '/admin',
  'Tidal User': process.env.REACT_APP_DSP_DASH_URL,
  'SX User': process.env.REACT_APP_SX_DASH_URL,
};

const ProtectedRoute = ({ children, requiredRole }) => {
  const { user, isAuthenticated } = useAuth0();
  const userRoles = user ? user[process.env.REACT_APP_AUTH0_ROLE_CLAIMS] || [] : [];

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  // Redirect according to the role before rendering children
  if (!userRoles.includes(requiredRole)) {
    const redirectTo = roleRedirectMap[userRoles.find((role) => roleRedirectMap[role])] || '/access-denied';
    if (!redirectTo.startsWith('/')) {
      window.location.href = redirectTo;
    } else {
      return <Navigate to={redirectTo} />;
    }
    return null; // Avoid rendering children
  }

  return children;
};

export default ProtectedRoute;
